dmx.global = {
    data: {},
    seed: Math.random(),
    get: function(name) {
        if (this.data.hasOwnProperty(name)) {
            return this.data[name];
        }

        return undefined;
    },
    set: function(name, value) {
        if (typeof name == 'object') {
            for (var prop in name) {
                this.set(prop, name[prop]);
            }
            return;
        }

        if (!dmx.equal(this.data[name], value)) {
            this.data[name] = value;
            dmx.requestUpdate();
        }
    },
    del: function(name) {
        delete this.data[name];
        dmx.requestUpdate();
    }
}

dmx.DataScope = function(data, parent) {
    return {
        parent: parent || dmx.global,
        data: data,
        seed: Math.random(),
        get: function(name) {
            if (this.data.hasOwnProperty(name)) {
                return this.data[name];
            }

            if (this.parent) {
                if (name == 'parent') {
                    return this.parent.data;
                }

                return this.parent.get(name);
            }

            return undefined;
        }
    };
};