dmx.Actions({

    'ajax.get': function(options) {
        var url = this.parse(options.url);

        return new Promise(function(resolve, reject) {
            var xhr = new XMLHttpRequest();

            xhr.onerror = reject;
            xhr.onabort = reject;
            xhr.ontimeout = reject;
            xhr.onload = function() {
                var data = JSON.parse(xhr.responseText);
                resolve(data);
            };

            xhr.open('GET', url);
            xhr.send();
        });
    }

});